import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = ({ location }) => {
  const lang = "ja";

  // Page setting
  const pageTitle = "NOT FOUND";
  const pageSlug = "notfound";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-sm">{pageTitle}</h1>
      </div>
      <article className="content-area" style={{ height: "64vh" }}>
        <div className="container-sm">
          <p>ごめんなさい。このページは存在しません。</p>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </article>
    </Layout>
  );
};

export default NotFoundPage;
